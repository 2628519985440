import React from 'react'
import { css } from '@emotion/core'
import Nav from '../components/nav'
import Privacy from '../components/policy/privacy'
import Footer from '../components/footer'
import SEO from '../components/seo'

export default function Home() {
  return (
    <div>
      <SEO title="隐私政策" description="policy" />
      {/* 背景图片 */}
      <div
        css={css`
          width: 100%;
          background: linear-gradient(
            180deg,
            rgba(246, 179, 82, 1) 0%,
            rgba(255, 147, 115, 1) 100%
          );
        `}
        className="policyNav"
      >
        <Nav />
        <div
          css={css`
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: rgba(255, 255, 255, 1);
              text-align: center;
            `}
            className="nav-title"
          >
            隐私政策
          </div>
          <Privacy />
        </div>
      </div>
      {/* 页脚 */}
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}
