/* eslint no-unused-vars: 0 */
import React from 'react'
import { css } from '@emotion/core'
import { Container } from 'react-bootstrap'
import style from './styles.css'

export default () => (
  <div className="privacy">
    <Container
      css={css`
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
        border-radius: 27px;
      `}
    >
      <div className="privacy-content">
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
          `}
          className="privacy-content-title"
        >
          我们深知个人信息对您而言的重要性，也感谢您对我们的信任。我们将通过本政策向您说明我们会如何收集、存储、保护、使用及对外提供您的信息，并说明您享有的权利，其中要点如下：
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
          `}
          className="privacy-content-text"
        >
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、为了便于您了解您在使用我们的服务时，我们需要收集的信息类型与用途，我们将结合具体服务向您逐一说明。
          <br /> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          2、为了向您提供服务所需，我们会按照合法、正当、必要的原则收集您的信息。
          <br /> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          3、如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施并且严格遵守相关法律法规与监管要求。另外，我们会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗提示等形式征得您的同意或确认第三方已经征得您的同意.
          <br /> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          4、如果为了向您提供服务而需要从第三方获取您的信息，我们将要求第三方说明信息来源，并要求第三方保障其提供信息的合法性；如果我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权范围，我们将征得您的明确同意。
          <br /> <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;

            text-indent: 32px;
          `}
          className="privacy-content-title"
        >
          您可以根据以下索引阅读相应章节，进一步了解本政策的具体约定：
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
          `}
          className="privacy-content-text"
        >
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 一、我们如何收集信息
          <br /> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;二、我们如何使用Cookie、Beacon、Proxy等技术
          <br /> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;三、我们如何存储和保护信息
          四、我们如何使用信息
          <br /> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;五、我们如何对外提供信息
          <br /> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;六、您如何访问和管理自己的信息
          <br /> <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您使用我们的服务时，我们将按照无锋网络银行账户实名认证服务隐私权政策（以下简称“本政策”）收集、存储、使用及对外提供您的信息。同时，我们会通过本政策向您说明，我们如何为您提供访问、更新、管理和保护您的信息的服务。本政策与您使用我们的服务关系紧密，我们建议您仔细阅读并理解本政策全部内容，做出您认为适当的选择。我们努力用通俗易懂、简明扼要的文字表达，并对本政策中与您的权益存在重大关系的条款和个人敏感信息，采用粗体字进行标注以提示您注意。
          <br /> <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;

            text-indent: 32px;
          `}
          className="privacy-content-title"
        >
          我们如何收集信息
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
          `}
          className="privacy-content-text"
        >
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在您使用银行账户实名认证（以下简称“服务”）的过程中，我们需要收集您的一些信息，用以向您提供服务：{' '}
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们只收集银行账户实名认证用户所必须提供的公司名称、对公账号用来做银行账户实名认证服务。{' '}
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请您理解，我们向您提供的服务是不断更新和发展的。如您选择使用了前述说明当中尚未涵盖的其他服务，基于该服务我们需要收集您的信息的，我们会通过页面提示、交互流程、协议约定的方式另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供及保护您的信息；如您选择不提供前述信息，您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。
          <br />
          <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
          `}
          className="privacy-content-title"
        >
          我们如何使用Cookie、Beacon、Proxy等技术
          <br />
          <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
          `}
          className="privacy-content-title"
        >
          我们如何存储和保护信息
          <br />
          <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
          `}
          className="privacy-content-text"
        >
          我们承诺我们将使信息安全保护达到业界领先的安全水平。为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。传输和存储敏感信息（含个人生物识别信息）时，我们将采用加密、权限控制、去标识化等安全措施。
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          使用该服务，我们通过HTTPS加密的方式收到认证方提供的公司账号和名称后，执行完转账认证操作后即销毁这些信息，本地不再存储这些信息。
          <br />
          <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
          `}
          className="privacy-content-title"
        >
          我们如何使用信息
          <br />
          <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
          `}
          className="privacy-content-text"
        >
          我们仅将被认证方提供的信息（公司名称、银行账号）用于完成实名认证使用，不会用作其他用途。
          <br />
          <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
          `}
          className="privacy-content-title"
        >
          我们如何对外提供信息
          <br />
          <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
          `}
          className="privacy-content-text"
        >
          使用银行账户实名认证服务，我们仅会对被认证方执行打款操作，并向认证提出方返回转账结果和转账金额，被认证方的其他信息不对外提供。
          <br />
          <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
          `}
          className="privacy-content-title"
        >
          您如何访问和管理自己的信息 <br />
          <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
          `}
          className="privacy-content-text"
        >
          无 <br />
          <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
          `}
          className="privacy-content-title"
        >
          本政策的适用及更新 <br />
          <br />
        </div>
        <div
          css={css`
            font-family: PingFangSC-Regular, PingFang SC;
          `}
          className="privacy-content-text"
        >
          无锋银行账户实名认证的所有服务均适用本政策，除非相关服务已有独立的隐私权政策或相应的用户服务协议当中存在特殊约定。
          <br />
          <br />
          <br />
          发生下列重大变化情形时，我们会适时对本政策进行更新：
          <br />
          <br />
          （1）我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；
          <br />
          <br />
          （2）收集、存储、使用个人信息的范围、目的、规则发生变化；
          <br />
          <br />
          （3）对外提供个人信息的对象、范围、目的发生变化；
          <br />
          <br />
          （4）您访问和管理个人信息的方式发生变化；
          <br />
          <br />
          （5）数据安全能力、信息安全风险发生变化；
          <br />
          <br />
          （6）用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；
          <br />
          <br />
          （7）其他可能对您的个人信息权益产生重大影响的变化。
          <br />
          <br />
        </div>
      </div>
    </Container>
  </div>
)
